  .custom-dropdown {
    width: 150px;
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .custom-dropdown:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .custom-dropdown option {
    padding: 10px;
    font-size: 14px;
  }